<template>
  <div class="sela-container min-h-screen my-10">
    <MaterialsCatogriesWithSupportFilter
      :support-filter="store?.support_filters === 1"
      :use-categories-domain="true"
      :fetch-params="{ store_id: store?.id, branch_id: currentBranchId }"
    />
    <MaterialsGrid
      class="mb-5 mt-2"
      with-query-params
      :fetch-params="{ store_id: store?.id, branch_id: currentBranchId }"
    />
  </div>
</template>

<script setup lang="ts">
import { useDomainCategoriesStore } from '~/store/domain_categories'
const currentBranchId = useBranchId()
const route = useRoute()
const { store } = useDomainState()
const { url } = useDomainHost()
const { t } = useI18n()

const { categoriesByCategoryStyle } = useDomainCategoriesStore()

const categoryMeta = computed(() => {
  if (route.query.category_id) {
    const category = categoriesByCategoryStyle.find(
      // @ts-ignore
      // eslint-disable-next-line eqeqeq
      (el) => el.id == route.query.category_id
    )
    if (category) {
      return {
        title: category.name,
        image: category.image
      }
    }
  }

  return {
    title: 'المنتجات',
    image: url + '/images/sela.webp'
  }
})

useServerSeoMeta(
  useOgMeta(
    url + '/materials',
    categoryMeta.value.title,
    store.value?.about,
    categoryMeta.value.image
  )
)

useHead({
  title: () => categoryMeta.value.title,
  link: [useSeoCanonical(url + '/materials')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, categoryMeta.value.title, url + '/materials')
    ])
  ]
})
</script>
